var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "system"
  }, [_c("el-form", {
    ref: "form",
    staticClass: "system",
    attrs: {
      model: _vm.config,
      "label-width": "100px"
    }
  }, [_c("h2", [_vm._v("系统配置")]), _c("el-form-item", {
    attrs: {
      label: "环境值"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.system.env,
      callback: function callback($$v) {
        _vm.$set(_vm.config.system, "env", $$v);
      },
      expression: "config.system.env"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "端口值"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.system.addr,
      callback: function callback($$v) {
        _vm.$set(_vm.config.system, "addr", _vm._n($$v));
      },
      expression: "config.system.addr"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "数据库类型"
    }
  }, [_c("el-select", {
    model: {
      value: _vm.config.system.dbType,
      callback: function callback($$v) {
        _vm.$set(_vm.config.system, "dbType", $$v);
      },
      expression: "config.system.dbType"
    }
  }, [_c("el-option", {
    attrs: {
      value: "mysql"
    }
  }), _c("el-option", {
    attrs: {
      value: "sqlite"
    }
  }), _c("el-option", {
    attrs: {
      value: "sqlserver"
    }
  }), _c("el-option", {
    attrs: {
      value: "postgresql"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Oss类型"
    }
  }, [_c("el-select", {
    model: {
      value: _vm.config.system.ossType,
      callback: function callback($$v) {
        _vm.$set(_vm.config.system, "ossType", $$v);
      },
      expression: "config.system.ossType"
    }
  }, [_c("el-option", {
    attrs: {
      value: "local"
    }
  }), _c("el-option", {
    attrs: {
      value: "qiniu"
    }
  }), _c("el-option", {
    attrs: {
      value: "tencent-cos"
    }
  }), _c("el-option", {
    attrs: {
      value: "aliyun-oss"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "配置文件环境变量名"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.system.configEnv,
      callback: function callback($$v) {
        _vm.$set(_vm.config.system, "configEnv", _vm._n($$v));
      },
      expression: "config.system.configEnv"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "数据初始化"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.config.system.needInitData,
      callback: function callback($$v) {
        _vm.$set(_vm.config.system, "needInitData", $$v);
      },
      expression: "config.system.needInitData"
    }
  }, [_vm._v("开启")])], 1), _c("el-form-item", {
    attrs: {
      label: "多点登录拦截"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.config.system.useMultipoint,
      callback: function callback($$v) {
        _vm.$set(_vm.config.system, "useMultipoint", $$v);
      },
      expression: "config.system.useMultipoint"
    }
  }, [_vm._v("开启")])], 1), _c("h2", [_vm._v("jwt签名")]), _c("el-form-item", {
    attrs: {
      label: "jwt签名"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.jwt.signingKey,
      callback: function callback($$v) {
        _vm.$set(_vm.config.jwt, "signingKey", $$v);
      },
      expression: "config.jwt.signingKey"
    }
  })], 1), _c("h2", [_vm._v("Zap日志配置")]), _c("el-form-item", {
    attrs: {
      label: "级别"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.zap.level,
      callback: function callback($$v) {
        _vm.$set(_vm.config.zap, "level", _vm._n($$v));
      },
      expression: "config.zap.level"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "输出"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.zap.format,
      callback: function callback($$v) {
        _vm.$set(_vm.config.zap, "format", $$v);
      },
      expression: "config.zap.format"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "日志前缀"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.zap.prefix,
      callback: function callback($$v) {
        _vm.$set(_vm.config.zap, "prefix", $$v);
      },
      expression: "config.zap.prefix"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "日志文件夹"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.zap.director,
      callback: function callback($$v) {
        _vm.$set(_vm.config.zap, "director", $$v);
      },
      expression: "config.zap.director"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "软链接名称"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.zap.linkName,
      callback: function callback($$v) {
        _vm.$set(_vm.config.zap, "linkName", $$v);
      },
      expression: "config.zap.linkName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "编码级"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.zap.encodeLevel,
      callback: function callback($$v) {
        _vm.$set(_vm.config.zap, "encodeLevel", $$v);
      },
      expression: "config.zap.encodeLevel"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "栈名"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.zap.stacktraceKey,
      callback: function callback($$v) {
        _vm.$set(_vm.config.zap, "stacktraceKey", $$v);
      },
      expression: "config.zap.stacktraceKey"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "显示行"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.config.zap.showLine,
      callback: function callback($$v) {
        _vm.$set(_vm.config.zap, "showLine", $$v);
      },
      expression: "config.zap.showLine"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "输出控制台"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.config.zap.logInConsole,
      callback: function callback($$v) {
        _vm.$set(_vm.config.zap, "logInConsole", $$v);
      },
      expression: "config.zap.logInConsole"
    }
  })], 1), _c("h2", [_vm._v("Redis admin数据库配置")]), _c("el-form-item", {
    attrs: {
      label: "db"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.redis.db,
      callback: function callback($$v) {
        _vm.$set(_vm.config.redis, "db", $$v);
      },
      expression: "config.redis.db"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "addr"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.redis.addr,
      callback: function callback($$v) {
        _vm.$set(_vm.config.redis, "addr", $$v);
      },
      expression: "config.redis.addr"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "password"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.redis.password,
      callback: function callback($$v) {
        _vm.$set(_vm.config.redis, "password", $$v);
      },
      expression: "config.redis.password"
    }
  })], 1), _c("h2", [_vm._v("邮箱配置")]), _c("el-form-item", {
    attrs: {
      label: "接收者邮箱"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "可多个，以逗号分隔"
    },
    model: {
      value: _vm.config.email.to,
      callback: function callback($$v) {
        _vm.$set(_vm.config.email, "to", $$v);
      },
      expression: "config.email.to"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "端口"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.email.port,
      callback: function callback($$v) {
        _vm.$set(_vm.config.email, "port", _vm._n($$v));
      },
      expression: "config.email.port"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "发送者邮箱"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.email.from,
      callback: function callback($$v) {
        _vm.$set(_vm.config.email, "from", $$v);
      },
      expression: "config.email.from"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "host"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.email.host,
      callback: function callback($$v) {
        _vm.$set(_vm.config.email, "host", $$v);
      },
      expression: "config.email.host"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "是否为ssl"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.config.email.isSSL,
      callback: function callback($$v) {
        _vm.$set(_vm.config.email, "isSSL", $$v);
      },
      expression: "config.email.isSSL"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "secret"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.email.secret,
      callback: function callback($$v) {
        _vm.$set(_vm.config.email, "secret", $$v);
      },
      expression: "config.email.secret"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "测试邮件"
    }
  }, [_c("el-button", {
    on: {
      click: _vm.email
    }
  }, [_vm._v("测试邮件")])], 1), _c("h2", [_vm._v("casbin配置")]), _c("el-form-item", {
    attrs: {
      label: "模型地址"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.casbin.modelPath,
      callback: function callback($$v) {
        _vm.$set(_vm.config.casbin, "modelPath", $$v);
      },
      expression: "config.casbin.modelPath"
    }
  })], 1), _c("h2", [_vm._v("验证码配置")]), _c("el-form-item", {
    attrs: {
      label: "keyLong"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.captcha.keyLong,
      callback: function callback($$v) {
        _vm.$set(_vm.config.captcha, "keyLong", _vm._n($$v));
      },
      expression: "config.captcha.keyLong"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "imgWidth"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.captcha.imgWidth,
      callback: function callback($$v) {
        _vm.$set(_vm.config.captcha, "imgWidth", _vm._n($$v));
      },
      expression: "config.captcha.imgWidth"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "imgHeight"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.captcha.imgHeight,
      callback: function callback($$v) {
        _vm.$set(_vm.config.captcha, "imgHeight", _vm._n($$v));
      },
      expression: "config.captcha.imgHeight"
    }
  })], 1), _vm.config.system.dbType == "mysql" ? [_c("h2", [_vm._v("mysql admin数据库配置")]), _c("el-form-item", {
    attrs: {
      label: "username"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.mysql.username,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "username", $$v);
      },
      expression: "config.mysql.username"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "password"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.mysql.password,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "password", $$v);
      },
      expression: "config.mysql.password"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "path"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.mysql.path,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "path", $$v);
      },
      expression: "config.mysql.path"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "dbname"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.mysql.dbname,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "dbname", $$v);
      },
      expression: "config.mysql.dbname"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "maxIdleConns"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.mysql.maxIdleConns,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "maxIdleConns", _vm._n($$v));
      },
      expression: "config.mysql.maxIdleConns"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "maxOpenConns"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.mysql.maxOpenConns,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "maxOpenConns", _vm._n($$v));
      },
      expression: "config.mysql.maxOpenConns"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "logMode"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.config.mysql.logMode,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "logMode", $$v);
      },
      expression: "config.mysql.logMode"
    }
  })], 1)] : _vm._e(), _vm.config.system.dbType == "sqlite" ? [_c("h2", [_vm._v("sqlite admin数据库配置")]), _c("el-form-item", {
    attrs: {
      label: "path"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.mysql.path,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "path", $$v);
      },
      expression: "config.mysql.path"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "maxIdleConns"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.mysql.maxIdleConns,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "maxIdleConns", _vm._n($$v));
      },
      expression: "config.mysql.maxIdleConns"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "maxOpenConns"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.mysql.maxOpenConns,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "maxOpenConns", _vm._n($$v));
      },
      expression: "config.mysql.maxOpenConns"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "logger"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.config.mysql.logger,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "logger", $$v);
      },
      expression: "config.mysql.logger"
    }
  })], 1)] : _vm._e(), _vm.config.system.dbType == "sqlserver" ? [_c("h2", [_vm._v("sqlserver admin数据库配置")]), _c("el-form-item", {
    attrs: {
      label: "username"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.sqlserver.username,
      callback: function callback($$v) {
        _vm.$set(_vm.config.sqlserver, "username", $$v);
      },
      expression: "config.sqlserver.username"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "password"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.sqlserver.password,
      callback: function callback($$v) {
        _vm.$set(_vm.config.sqlserver, "password", $$v);
      },
      expression: "config.sqlserver.password"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "path"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.sqlserver.path,
      callback: function callback($$v) {
        _vm.$set(_vm.config.sqlserver, "path", $$v);
      },
      expression: "config.sqlserver.path"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "dbname"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.sqlserver.dbname,
      callback: function callback($$v) {
        _vm.$set(_vm.config.sqlserver, "dbname", $$v);
      },
      expression: "config.sqlserver.dbname"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "maxIdleConns"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.sqlserver.maxIdleConns,
      callback: function callback($$v) {
        _vm.$set(_vm.config.sqlserver, "maxIdleConns", _vm._n($$v));
      },
      expression: "config.sqlserver.maxIdleConns"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "maxOpenConns"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.sqlserver.maxOpenConns,
      callback: function callback($$v) {
        _vm.$set(_vm.config.sqlserver, "maxOpenConns", _vm._n($$v));
      },
      expression: "config.sqlserver.maxOpenConns"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "logger"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.config.sqlserver.logger,
      callback: function callback($$v) {
        _vm.$set(_vm.config.sqlserver, "logger", $$v);
      },
      expression: "config.sqlserver.logger"
    }
  })], 1)] : _vm._e(), _vm.config.system.dbType == "postgresql" ? [_c("h2", [_vm._v("postgresql admin数据库配置")]), _c("el-form-item", {
    attrs: {
      label: "username"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.mysql.username,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "username", $$v);
      },
      expression: "config.mysql.username"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "password"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.mysql.password,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "password", $$v);
      },
      expression: "config.mysql.password"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "dbName"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.mysql.dbName,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "dbName", $$v);
      },
      expression: "config.mysql.dbName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "port"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.mysql.port,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "port", $$v);
      },
      expression: "config.mysql.port"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "config"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.mysql.config,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "config", $$v);
      },
      expression: "config.mysql.config"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "maxIdleConns"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.mysql.maxIdleConns,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "maxIdleConns", _vm._n($$v));
      },
      expression: "config.mysql.maxIdleConns"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "maxOpenConns"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.mysql.maxOpenConns,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "maxOpenConns", _vm._n($$v));
      },
      expression: "config.mysql.maxOpenConns"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "logger"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.config.mysql.logger,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "logger", $$v);
      },
      expression: "config.mysql.logger"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "prefer-simple-protocol"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.config.mysql.preferSimpleProtocol,
      callback: function callback($$v) {
        _vm.$set(_vm.config.mysql, "preferSimpleProtocol", $$v);
      },
      expression: "config.mysql.preferSimpleProtocol"
    }
  })], 1)] : _vm._e(), _vm.config.system.ossType == "local" ? [_c("h2", [_vm._v("本地上传配置")]), _c("el-form-item", {
    attrs: {
      label: "本地文件路径"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.local.path,
      callback: function callback($$v) {
        _vm.$set(_vm.config.local, "path", $$v);
      },
      expression: "config.local.path"
    }
  })], 1)] : _vm._e(), _vm.config.system.ossType == "qiniu" ? [_c("h2", [_vm._v("qiniu上传配置")]), _c("el-form-item", {
    attrs: {
      label: "存储区域"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.qiniu.zone,
      callback: function callback($$v) {
        _vm.$set(_vm.config.qiniu, "zone", $$v);
      },
      expression: "config.qiniu.zone"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "空间名称"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.qiniu.bucket,
      callback: function callback($$v) {
        _vm.$set(_vm.config.qiniu, "bucket", $$v);
      },
      expression: "config.qiniu.bucket"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "CDN加速域名"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.qiniu.imgPath,
      callback: function callback($$v) {
        _vm.$set(_vm.config.qiniu, "imgPath", $$v);
      },
      expression: "config.qiniu.imgPath"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "是否使用https"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.config.qiniu.imgPath,
      callback: function callback($$v) {
        _vm.$set(_vm.config.qiniu, "imgPath", $$v);
      },
      expression: "config.qiniu.imgPath"
    }
  }, [_vm._v("开启")])], 1), _c("el-form-item", {
    attrs: {
      label: "accessKey"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.qiniu.accessKey,
      callback: function callback($$v) {
        _vm.$set(_vm.config.qiniu, "accessKey", $$v);
      },
      expression: "config.qiniu.accessKey"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "secretKey"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.qiniu.secretKey,
      callback: function callback($$v) {
        _vm.$set(_vm.config.qiniu, "secretKey", $$v);
      },
      expression: "config.qiniu.secretKey"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "上传是否使用CDN上传加速"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.config.qiniu.useCdnDomains,
      callback: function callback($$v) {
        _vm.$set(_vm.config.qiniu, "useCdnDomains", $$v);
      },
      expression: "config.qiniu.useCdnDomains"
    }
  }, [_vm._v("开启")])], 1)] : _vm._e(), _vm.config.system.ossType == "tencent-cos" ? [_c("h2", [_vm._v("腾讯云COS上传配置")]), _c("el-form-item", {
    attrs: {
      label: "bucket"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.tencentCOS.bucket,
      callback: function callback($$v) {
        _vm.$set(_vm.config.tencentCOS, "bucket", $$v);
      },
      expression: "config.tencentCOS.bucket"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "region"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.tencentCOS.region,
      callback: function callback($$v) {
        _vm.$set(_vm.config.tencentCOS, "region", $$v);
      },
      expression: "config.tencentCOS.region"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "secretID"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.tencentCOS.secretID,
      callback: function callback($$v) {
        _vm.$set(_vm.config.tencentCOS, "secretID", $$v);
      },
      expression: "config.tencentCOS.secretID"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "secretKey"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.tencentCOS.secretKey,
      callback: function callback($$v) {
        _vm.$set(_vm.config.tencentCOS, "secretKey", $$v);
      },
      expression: "config.tencentCOS.secretKey"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "pathPrefix"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.tencentCOS.pathPrefix,
      callback: function callback($$v) {
        _vm.$set(_vm.config.tencentCOS, "pathPrefix", $$v);
      },
      expression: "config.tencentCOS.pathPrefix"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "baseURL"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.tencentCOS.baseURL,
      callback: function callback($$v) {
        _vm.$set(_vm.config.tencentCOS, "baseURL", $$v);
      },
      expression: "config.tencentCOS.baseURL"
    }
  })], 1)] : _vm._e(), _vm.config.system.ossType == "aliyun-oss" ? [_c("h2", [_vm._v("阿里云OSS上传配置")]), _c("el-form-item", {
    attrs: {
      label: "endpoint"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.aliyunOSS.endpoint,
      callback: function callback($$v) {
        _vm.$set(_vm.config.aliyunOSS, "endpoint", $$v);
      },
      expression: "config.aliyunOSS.endpoint"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "accessKeyId"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.aliyunOSS.accessKeyId,
      callback: function callback($$v) {
        _vm.$set(_vm.config.aliyunOSS, "accessKeyId", $$v);
      },
      expression: "config.aliyunOSS.accessKeyId"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "accessKeySecret"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.aliyunOSS.accessKeySecret,
      callback: function callback($$v) {
        _vm.$set(_vm.config.aliyunOSS, "accessKeySecret", $$v);
      },
      expression: "config.aliyunOSS.accessKeySecret"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "bucketName"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.aliyunOSS.bucketName,
      callback: function callback($$v) {
        _vm.$set(_vm.config.aliyunOSS, "bucketName", $$v);
      },
      expression: "config.aliyunOSS.bucketName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "bucketUrl"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.config.aliyunOSS.bucketUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.config.aliyunOSS, "bucketUrl", $$v);
      },
      expression: "config.aliyunOSS.bucketUrl"
    }
  })], 1)] : _vm._e(), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.update
    }
  }, [_vm._v("立即更新")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.reload
    }
  }, [_vm._v("重启服务（开发中）")])], 1)], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };